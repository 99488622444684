<template>
    <div>
        <a-spin :spinning="loading">
            <div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
                <div style="width: 800px;">
                    <div class="ft20 ftw500 cl-main">充值套餐</div>
                    <a-alert class="mt20" message="充值套餐设置" type="info" />
                    <a-form layout="inline" class="mt20">
                        <a-form-item label="套餐规格设置">
                            <div class="package_th">
                                <div>时长</div>
                                <div>原价</div>
                                <div>售价</div>
                                <div>标签</div>
                                <div>活动说明</div>
                                <p>
                                    <a-button type="primary" ghost @click="onAdd">新增</a-button>
                                </p>
                            </div>
                        </a-form-item>
                        <div>
                            <div class="flex-row-start" v-for="(item,index) in dataList" :key="index">
                                <div class="package_tb">
                                    <a-input v-model="item.duration" placeholder="请输入时长" style="width: 160px">
                                        <template #addonAfter>
                                            <a-select v-model="item.duration_type" style="width: 60px">
                                                <a-select-option :value="1">天</a-select-option>
                                                <a-select-option :value="2">月</a-select-option>
                                                <a-select-option :value="3">年</a-select-option>
                                            </a-select>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="package_tb">
                                    <a-input v-model="item.original_price" placeholder="请输入原价" style="width: 160px">
                                        <template #addonAfter>
                                            <div style="width:50px">元</div>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="package_tb">
                                    <a-input v-model="item.price" placeholder="请输入售价" style="width: 160px">
                                        <template #addonAfter>
                                            <div style="width:50px">元</div>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="package_tb">
                                    <a-input :maxLength="3" v-model="item.label" placeholder="标签(3字内)" style="width: 160px"> </a-input>
                                </div>
                                <div class="package_tb">
                                    <a-input :maxLength="6" v-model="item.illustrate" placeholder="活动描述(6字内)" style="width: 160px"> </a-input>
                                </div>
                                <div class="close_circle flex-row-center" @click="delItem(index)" v-if="dataList.length>1">
                                    <a-icon type="close-circle" />
                                </div>
                            </div>
                        </div>
                        <a-button type="primary" @click="onSubmit()" :loading="confirmLoading">
                            保存设置
                        </a-button>
                    </a-form>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            dataList: [
                { duration: 0, duration_type: 1, original_price: 0, price: 0, label: '', illustrate: '' }
            ],
            thhead: [
                { title: '时长' },
                { title: '原价' },
                { title: '时长' },
                { title: '时长' }
            ],
            value: '',
            value1: '天'

        }
    },
    created() {
        this.loaddata();
    },
    mounted() {
    },
    methods: {
        onAdd() {
            this.dataList.push({ duration: 0, duration_type: 1, original_price: 0, price: 0, label: '', illustrate: '' })
        },
        delItem(index) {
            this.dataList.splice(index, 1);
        },
        loaddata() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/Install/InstallInfo', {
                type: 'Recharge'
            }).then(res => {
                this.loading = false;
                this.dataList = []
                res.data.json_data.forEach(item => {
                    this.dataList.push({
                        duration: item.duration.value,
                        duration_type: item.duration_type.value,
                        original_price: item.original_price.value,
                        label: item.label.value,
                        illustrate: item.illustrate.value,
                        price: item.price.value,
                    })
                });
            }).catch(res => {
                this.loading = false;
            })
        },
        onSubmit() {
            if (this.confirmLoading == true) return;
            this.confirmLoading = true;
            let datas = {
                Package_specifications: JSON.stringify(this.dataList)
            }
            this.$http.api('api/Install/RechargeEdit', datas).then(res => {
                this.confirmLoading = false;
                this.$message.success('保存成功');
            }).catch(res => {
                console.log(res);
                this.confirmLoading = false;
            })
        },

    }
}
</script>

<style lang="less" scoped>
.package_th {
    background: #fafafa;
    border: 1px solid #f0f0f0;
    display: flex;
    div {
        width: 190px;
        text-align: center;
        line-height: 40px;
    }
    p {
        margin: 0;
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.package_tb {
    width: 230px;
    padding: 20px 15px;
}

.close_circle {
    width: 100px;
    cursor: pointer;

    /deep/ .anticon {
        color: red;
        font-size: 16px;
    }
}
/deep/ .anticon {
    color: #333333;
}
</style>
